( function ($) {
    var player = null;
    var slideshow_container = $('.slideshow_container');

    function slideshow_video_controls(player){

        $('.controls a.stop').on('click', function () {
            $('.slideshow_container').removeClass('playing-video');
            player.stop();
            player.volume = 0;
            $('.player_wrapper').fadeOut('fast');
            $('.controls .muted_to_play').hide();
            $('.controls .pause').show();
            $('.slideshow').slick('slickPlay');
        });
        $('.controls a.pause').on('click', function () {
            player.pause();
            $(this).hide();
            $('.controls .muted_to_play').show();
        });
        $('.controls a.muted_to_play').on('click', function () {
            player.play();
            $(this).hide();
            $('.controls .pause').show();
            $('.controls .unmute').hide();
            $('.controls .mute').show();
        });
        $('.controls a.mute').on('click', function () {
            player.volume = 0;
            $(this).hide();
            $('.controls .unmute').show();
        });
        $('.controls a.unmute').on('click', function () {
            player.volume = 0.5;
            $(this).hide();
            $('.controls .mute').show();
        });
    }


    
    function lazySlide(array, index) {
        var sources = $(array[index]).find('source');
        var img = $(array[index]).find('img');
        var src = img.attr('data-src');

        sources.each(function () {
            var source = $(this).attr('data-srcset');
            $(this).attr('srcset', source);
            $(this).attr('data-processed', 'true');
            $(this).removeAttr('data-srcset');

        });
        img.attr('src', src);
        img.attr('data-processed', 'true');
        img.removeAttr('data-src');
    }

    function doSlideshow() {
        var wrap_external = $('.slideshow_container');
        var wrap = $('.slideshow');
        if (wrap.length > 0) {

            //LAZY LOADING (before carousel is loaded)
            wrap.on("init", function () {

                //load 1st, 2nd and last slide
                var slides = wrap.find('.slide');
                var slidesLoaded = [slides[1], slides[2], slides[slides.length]];

                if(slides.length <= 1){
                    dots = false;
                    slidesLoaded = [slides[0]];
                }

                slides.each(function (index) {
                    lazySlide(slidesLoaded, index);
                })

                $('.slick-dots button').attr('tabindex', '-1');
            });

            var rtl = ($('html').attr('lang') === 'ar');

            //slideshow setup
            wrap.slick({
                rows: 0,
                fade: false,
                pauseOnHover: false,
                pauseOnFocus: false,
                autoplay: false,
                arrows: true,
                dots: false,
                // dots: true,
                infinite: true,
                rtl: rtl,
                // autoplaySpeed: autoplaySpeed,
                speed: 1200,
                nextArrow: wrap.parent().find('.next'),
                prevArrow: wrap.parent().find('.prev'),
                // appendDots: wrap.parent().find('.pager_slider')
            });
        }

        //LAZY LOADING (after carousel is loaded)

        //load 1st, 2nd and last clone
        var clones = wrap.find('.slick-cloned');
        var clonesLoaded = [clones[0], clones[1], clones[2]];

        clones.each(function (index) {
            lazySlide(clonesLoaded, index);
        });

        wrap.on("afterChange", function (e, slick) {
            var slides = $.merge(slick.$slides, clones);

            //load next slide + 1
            var index = slick.currentSlide + 1;

            lazySlide(slides, index);

            //load previous slide - 1
            if (slick.currentSlide === 0) {
                index = slides.length - 1;
            } else {
                index = slick.currentSlide - 1;
            }
            lazySlide(slides, index);

            //load current slide if click on pager
            var indexCurrent = slick.currentSlide;

            lazySlide(slides, indexCurrent);
        });

        wrap_external.find('.video_play').each(function () {
           var play = $(this);
           var video_id = play.data('video-id');
           play.click(function (e) {
               e.preventDefault();
               initVideoPlayer(video_id);
           });
        });
    }


    $(document).ready(function () {
        doSlideshow();
    });

    $(window).on('load', function () {

        var player_icon = $('.play-icon-video');
        if(player_icon.length > 0){
            player_icon.on('click', function(){

                var script = document.createElement('script');
                script.defer = true;
                script.src = 'https://cdn.plyr.io/3.7.2/plyr.polyfilled.js';

                document.head.appendChild(script);

                setTimeout(function(){

                    $('.slideshow_container iframe').attr('src', $('.slideshow_container iframe').data('src'));

                    var player = new Plyr('#player', {
                        volume: 0,
                        controls: ['play','mute', 'volume'],
                    });

                    $('.slideshow').slick('slickPause');
                    $('.slideshow_container').addClass('playing-video');
                    $('.player_wrapper').fadeIn('fast');
                    setTimeout(function (){
                        player.play();
                        player.volume = 0;

                    },1000);

                    player.on('ended', function(){
                        if(video_autoplay && video_loop){
                            setTimeout(function (){
                                player.play();
                            },1000);
                        } else {
                            $('.slideshow_container').removeClass('playing-video');
                            $('.player_wrapper').fadeOut('fast');
                            player.stop();
                            $('.slideshow').slick('slickPlay');
                        }
                    });

                    slideshow_video_controls(player);

                }, 200);

            });
        }

    });


}(jQuery) );
